import React from "react";
import { FormattedMessage } from "react-intl";
import { Card, Col, Row } from "reactstrap";

import { LoginButton } from "../LoginButton";
import { QueryBoxInfo } from "../../MainContainer/Shared/Application/ContactInfo/QueryBox/QueryBoxInfo";
import terms from "../../../assets/images/termsLink.svg";
import drawing from "../../../assets/images/undraw_hiring_cyhs.svg";

import "./PortalLoginPage.scss";
import {
  PortalLoginPageHeaderContent, PortalLoginPageTextContent
} from "../../MainContainer/Shared/Application/ContactInfo/QueryBox/QueryBoxContent";

interface Props {
  setIsLoading: (value: boolean) => void;
}

export const PortalLoginPage = ({ setIsLoading }: Props) => (
  <Card className="content-container-card">
    <Row className="m-0">
      <Col xl={4} className="text-center text-xl-right pt-1 pr-0 order-xl-last">
        <img
          src={drawing}
          alt="pilt avalduste esitamisest"
          className="content-container-card-img"
        />
      </Col>
      <Col xl={8} className="p-0 order-xl-1">
        <Row className="content-container-card-large-text login-title-margins">
          <div>
            <FormattedMessage
              id="portalLoginPage.welcomeText"
              defaultMessage="Terviseameti registrite iseteeninduses saad ennast registreerida tervishoiutöötajaks (THT-ks) ja muuta enda andmeid."
            />
          </div>
        </Row>
        <Row className="font-weight-500 login-title-margins">
          <div>
            <p>
              <FormattedMessage
                id="portalLoginPage.welcomeText1"
                defaultMessage="Lugupeetud tervishoiuteenuse osutajad."
              />
            </p>
            <p>
              <FormattedMessage
                id="portalLoginPage.welcomeText2"
                defaultMessage="Kui Teil on tekkinud probleem või ettepanek MEDRE2 infosüsteemi kasutamisel, siis palun kirjeldage seda võimalikult detailselt ja saatke see <a>medre@terviseamet.ee</a>. Soovitame tutvuda meie kodulehel olevate juhenditega ja vajadusel võtta otse meiega ühendust konkreetsete küsimuste puhul."
                values={{
                  a: (msg: string) => (
                    <a
                      href="mailto:medre@terviseamet.ee"
                      rel="noopener noreferrer"
                      target={"_blank"}
                      style={{textDecoration:"underline"}}
                    >
                      { msg }
                    </a>
                  )
                }}
              />
            </p>
            <QueryBoxInfo
              useValue={() => {
              }}
              className={"font-weight-normal mw-100 ml-0 my-4"}
              headerContent={PortalLoginPageHeaderContent}
              textContent={PortalLoginPageTextContent}/>
          </div>
        </Row>
        <Row className="ml-3 ml-sm-4">
          <LoginButton setIsLoading={setIsLoading} />
        </Row>
        <Row className="acknowledgement-row">
          <div>
            <FormattedMessage
              id="portalLoginPage.acknowledgment"
              defaultMessage="<a>Isikuandmete töötlemine Terviseametis</a>"
              values={{
                a: (msg: string) => (
                  <a
                    href="https://terviseamet.ee/amet-uudised-ja-kontakt/dokumendid-riigiloivud/isikuandmete-tootlemine"
                    rel="noopener noreferrer"
                    target={"_blank"}
                  >
                    {msg}
                  </a>
                )
              }}
            />
          </div>
        </Row>
      </Col>
    </Row>
  </Card>
);
